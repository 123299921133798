<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 15rem">
        <el-table
            :data="info"
            highlight-current-row
            max-height="1100"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="头图">
            <template #default="scope">
              <el-image :src="scope.row.pic" style="width: 1.92rem; height: 1.08rem"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="页面名称" prop="pageName"></el-table-column>
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button @click="this.$router.push({path: scope.row.pagePath ,query: {caseType: scope.row.SecPath}})">查看</el-button>
              <el-upload  :on-change="fileChange" :show-file-list="false" :auto-upload="false">
                <el-button type="danger" @click="changePic(scope.row.pageType)">修改</el-button>
              </el-upload>
            </template>
          </el-table-column>
        </el-table>
      </el-card>

      <el-card>
        首页轮播图
        <el-upload  :on-change="HomePicChange" :show-file-list="false" :auto-upload="false">
          <el-button type="primary">添加</el-button>
        </el-upload>

        <div v-for="(item, i) in HomePic">
          <img :src="item" alt="" style="width: 100px">
          <el-button type="danger" @click="deleteHomePic(i)">删除</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
let formData = new FormData();
let homePic = new FormData();
export default {
  name: "HeadPicManage",
  data(){
    return{

      info:[{
        pic:'',
        pageName:'供应链首页',
        pageType:'supplyChainHeader',
        pagePath:'/ChainList',
        SecPath:'',
      },{
        pic:'',
        pageName:'案例-产品设计',
        pageType:'caseProductDesignHeader',
        pagePath:'/Case',
        SecPath: '产品设计'
      },{
        pic:'',
        pageName:'案例-研发工程',
        pageType:'caseRdEngineerHeader',
        pagePath:'/Case',
        SecPath: '研发工程'
      },{
        pic:'',
        pageName:'案例-传播设计',
        pageType:'caseCommunicationDesignHeader',
        pagePath:'/Case',
        SecPath: '传播设计'
      },{
        pic:'',
        pageName:'案例-空间设计',
        pageType:'caseInteriorDesignHeader',
        pagePath:'/Case',
        SecPath: '空间设计'
      },
      ],
      HomePic:[],
      subHome:{
        homePics:'',
      }
    }
  },
  mounted() {
      this.getInf();
      this.getHomePic();
    },
  methods:{
    getInf(){
      this.$http({
        method: 'get',
        url: '/getHeaderPic',
        data: '',
      }).then(res => {
        this.info[0].pic = res.data.supplyChainHeader;
        this.info[1].pic = res.data.caseProductDesignHeader;
        this.info[2].pic = res.data.caseRdEngineeringHeader;
        this.info[3].pic = res.data.caseCommunicationDesignHeader;
        this.info[4].pic = res.data.caseInteriorDesignHeader;
      })
    },

    getHomePic(){
      this.$http({
        method: 'post',
        url: '/getHomeHeaderPics',
        data: '',
      }).then(res => {
        this.HomePic = res.data.homePics;
      })
    },

    HomePicChange(files, fileList){
      homePic.append('files',fileList[0].raw)
      this.submitHomePic();
    },

    deleteHomePic(index){
      for(let i = index;i<this.HomePic.length-1;i++){
        this.HomePic[i] = this.HomePic[i+1];
      }
      this.HomePic.pop();
      this.updateHomePic();
    },

    fileChange(files, fileList){
      formData.append('file',fileList[0].raw)
      this.submit();
    },

    changePic(index){
      formData.append('type',index);
    },

    submit(){
      this.$http({
        method: 'post',
        url: '/updateHeaderPic',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '上传成功',
            message: '',
            type: 'success'
          });
          location.reload();
        }
      })
    },

    submitHomePic(){
      this.$http({
        method: 'post',
        url: '/uploadHomeHeaderPics',
        data: homePic,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.HomePic.push(res.data[0]);
        this.updateHomePic();

      })
    },

    updateHomePic(){
      this.subHome.homePics = this.HomePic;
      this.$http({
        method: 'post',
        url: '/updateHomeHeaderPics',
        data: this.subHome,
      }).then(res => {
        this.getHomePic();
        location.reload();
      })
    }
  }
}
</script>

<style scoped>
.bac{
  height: 11.94rem;
}
</style>